import { AlertProps } from "props/alert.props";
import { useEffect } from "react";
import MuiAlert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";

export default function Alert(props: AlertProps) {
  useEffect(() => {
    if (props.open)
      setTimeout(() => {
        props.setOpen(false);
      }, 3000);
  }, [props.open]);

  return (
    <>
      {props.open && (
        <Stack
          sx={{ width: "40%", position: "absolute", bottom: 10, left: 10 }}
          spacing={2}
        >
          <MuiAlert severity={props.severity}>{props.message}</MuiAlert>
        </Stack>
      )}
    </>
  );
}
