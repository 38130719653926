const motion = require("../assets/vid/motion_sous_titres.mov");
import _ from "lodash";
import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useState } from "react";
import classNames from "classnames/bind";
import moment from "moment";

function Polygone(props) {
  let [nodes, setNodes] = useState([]);
  let { year } = useParams();

  let canvas = useRef(null);
  let lair = useRef(null);
  let menu = useRef(null);

  let navigate = useNavigate();

  const Constants = {
    Animation: {
      EASING_LINEAR: "linear",
      EASING_EASEIN: "easeIn",
      EASING_EASEOUT: "easeOut",
      EASING_EASEINOUT: "easeInOut",
      EASING_ACCELERATE: "accelerateDecelerate",
      EASING_DESCENDING: "descendingEntrance",
    },
    Rotation: {
      MEDIAN_AXIS: "median",
      CENTER_AXIS: "center",
      LEFT_AXIS: "left",
      RIGHT_AXIS: "right",
    },
    Coloring: {
      COLORING_LINEAR: "linear",
      COLORING_RANDOM: "random",
    },
  };

  const settings = {
    drawLineNode: true,
    // Node text font
    nodeFont: "1.7rem Arial",
    // Indicates the time (in seconds) to pause after a node has reached its destination. Default: 1
    restNodeMovements: 0 * 1000,
    // Indicates how long (in seconds) it will take for a node to move from start to finish. Default: 3
    duration: 5 * 1000,
    // Indicates the maximum (will be randomized) distance a node can move (in pixles) from its starting position. Default: 100
    nodeMovementDistance: window.innerHeight - (true ? 200 : 600),
    // Indicates the maximum (will be randomized) distance a node can have in depth (for a better 3D effect). Default: 300
    node3dDepthDistance: 150,
    // If set to true, the animation will rotate. Default: false
    node3dRotate: false,
    // If node3dRotate is set to true, the following option indicate if rotation should pause between n restNodeMovements. Default: 1
    node3dRotateOnNthNodeMovement: 1,
    // If node3dRotate is set to true, the following option indicate the alpha of the nodes at the far end of the rotation, creating depth. Default: 0.1
    node3dRotateDepthAlpha: 0.1,
    // If node3dRotate is set to true, the following option indicates the ease mode of each node movement (linear, easeIn, easeOut, easeInOut, accelerateDecelerate). Default: linear
    node3dRotatEase: "linear",
    // If node3dRotate is set to true, the following option indicate the axis on the canvas around which the animation will rotate (median, center, left, right). Default: center
    node3dRotateAxis: "center",
    // Indicates how many nodes to paint which relation can be filled (note: nodeFillSapce must be set to true). Default: 20
    numberOfNodes: props.nodes.length,
    // Indicates how many nodes to paint that does not create relations that can be filled. Default: 35
    numberOfUnconnectedNode: 0,
    // Indicates if a line should be drawn between unconnected nodes. Default: true
    ConnectUnconnectedNodes: true,
    // Indicates the maximum distance between unconnected nodes to draw the line. Default: 250
    ConnectUnconnectedNodesDistance: 250,
    // Indicates the maximum painted size of each node's "dot".
    nodeDotSize: props.nodes.length,
    // Indicates the ease mode of each node movement (linear, easeIn, easeOut, easeInOut, accelerateDecelerate). Default: easeOut
    nodeEase: "linear",
    // If true, the nodes starting position will descend into place on load. Default: false
    nodeFancyEntrance: false,
    // If true, each nodes starting position will be randomized within the canvas size. If false, each nodes position must be specified manually. Default: true
    randomizePolygonMeshNetworkFormation: true,
    // Indicates the positioning of each nodes starting position (note: randomizePolygonMeshNetworkFormation must be set to false). Default: null
    specifyPolygonMeshNetworkFormation: null,
    // Indicates how many nodes of the "numberOfNodes" that will be connected. Default: 3
    nodeRelations: 5,
    // Indicates the frame rate at which to update each node movement. Default: 30
    animationFps: 60,
    // Indicates the color (RGB), or an array of colors, of each node's "dot". Default: "200, 200, 200"
    nodeDotColor: "0,0,0",
    // If nodeDotColor is set to an array of colors, this option indicates in what order to pick the colors (linear or random). Default: linear
    nodeDotColoringSchema: "linear",
    // Indicates the color (RGB), or an array of colors, of the line drawn between connected nodes. Default: "150, 150, 150"
    nodeLineColor: "0,0,0",
    // If nodeLineColor is set to an array of colors, this option indicates in what order to pick the colors (linear or random). Default: linear
    nodeLineColoringSchema: "linear",
    // Indicates the fill color (RGB), or an array of colors, between each connected node. Default: "100, 100, 100"
    nodeFillColor: "0,0,0",
    // If nodeFillColor is set to an array of colors, this option indicates in what order to pick the colors (linear or random). Default: linear
    nodeFillColoringSchema: "linear",
    // Indicates the linear gradient to the fill color (RGB), or an array of colors, between each connected node. Default: null
    nodeFillGradientColor: null,
    // If nodeFillGradientColor is set to an array of colors, this option indicates in what order to pick the colors (linear or random). Default: linear
    nodeFillGradientColoringSchema: "linear",
    // Indicates the fill color's alpha level (1-0). Default: 0.5
    nodeFillAlpha: 0.7,
    // Indicates the alpha level (1-0) of the line drawn between connected nodes. Default: 0.5
    nodeLineAlpha: 1,
    // Indicates the alpha level (1-0) of each node's "dot". Default: 1.0
    nodeDotAlpha: 1,
    // Indicates the probability (1-0) of showing the coordinates for each nodes final position. Default: 0
    nodeDotPrediction: 0,
    // If true, the relation between connected nodes will be filled. Default: true
    nodeFillSapce: true,
    // If true, each node's final position can be outside the canvas boundary. Default: true
    nodeOverflow: false,
    // If true, a glowing effect is added to each node, its relations and fill respectively. Default: false
    nodeGlowing: false,
    // Indicates the width of the canvas on which to paint each node. Default: $(this).width()
    canvasWidth: document.body.scrollWidth,
    // Indicates the height of the canvas on which to paint each node. Default: $(this).height();
    canvasHeight: document.body.scrollHeight,
    // Indicate the CSS position property by which to position the canvas.current. Default: "absolute"
    canvasPosition: "absolute",
    // Indicate the CSS top property by which to vertically position the canvas.current. Default: "auto"
    canvasTop: "auto",
    // Indicate the CSS bottom property by which to vertically position the canvas.current. Default: "auto"
    canvasBottom: "auto",
    // Indicate the CSS right property by which to horizontally position the canvas.current. Default: "auto"
    canvasRight: "auto",
    // Indicate the CSS left property by which to horizontally position the canvas.current. Default: "auto"
    canvasLeft: "auto",
    // Indicate the CSS z-index property by which to specify the stack order of the canvas.current. Default: "auto"
    canvasZ: "auto",
  };

  var m_nodeMovementDistance = settings.nodeMovementDistance;

  var m_requestAnimationFrame =
    window.requestAnimationFrame ||
    window.mozRequestAnimationFrame ||
    window.webkitRequestAnimationFrame ||
    window.oRequestAnimationFrame ||
    window.msRequestAnimationFrame;

  var m_cancelAnimationFrame =
    window.cancelAnimationFrame ||
    window.mozCancelAnimationFrame ||
    window.webkitCancelAnimationFrame ||
    window.oCancelAnimationFrame ||
    window.msCancelAnimationFrame;

  var isRunning = false;
  var isHovered = false;

  var m_3dRotateOnNthNodeMovement = 0;
  var m_cosAngle;
  var m_entranceSingleton = true;
  var m_frameCount = -1;
  var m_lastFrameUpdate = null;
  var m_newTargetPossition = true;
  var m_rotationAxis = 0;
  var m_rotX;
  var m_rotZ;
  var m_sinAngle;
  var m_startTime = null;
  var m_turnAngle = 0;
  var m_turnSpeed = (2 * Math.PI) / 100;
  var m_requestId = -1;

  const refresh = () => {
    clear();

    settings.canvasWidth = menu.current.offsetWidth;
    settings.canvasHeight = menu.current.offsetHeight;

    if (canvas.current) {
      canvas.current.width = settings.canvasWidth;
      canvas.current.height = settings.canvasHeight;
      canvas.current.style.position = settings.canvasPosition;
      canvas.current.style.top = settings.canvasTop;
      canvas.current.style.bottom = settings.canvasBottom;
      canvas.current.style.right = settings.canvasRight;
      canvas.current.style.left = settings.canvasLeft;
      canvas.current.style.zIndex = settings.canvasZ;
    }

    setupClusterNodes();
  };

  const clear = () => {
    stop();
    canvas.current
      ?.getContext("2d")
      .clearRect(
        0,
        0,
        canvas.current?.getContext("2d").canvas.width,
        canvas.current?.getContext("2d").canvas.height
      );
  };

  const stopMoving = () => {
    isHovered = true;
  };

  const startMoving = () => {
    isHovered = false;
  };

  const stop = () => {
    if (isRunning) {
      m_cancelAnimationFrame(m_requestId);
      isRunning = false;
      m_startTime = null;
      m_frameCount = -1;
    }
  };

  const start = () => {
    if (!isRunning) {
      isRunning = true;
      isHovered = false;
      m_requestId = m_requestAnimationFrame(step);
    }
  };

  const step = (timestamp) => {
    if (!m_startTime) m_startTime = timestamp;
    if (!m_lastFrameUpdate) m_lastFrameUpdate = timestamp;
    let currentFrame = Math.floor(
      (timestamp - m_startTime) / (1000 / settings.animationFps)
    );

    if (m_frameCount < currentFrame) {
      m_frameCount = currentFrame;
      let currentDuration = timestamp - m_lastFrameUpdate;
      if (currentDuration <= settings.duration) {
        if (m_newTargetPossition) {
          setNewTargetPossition();
          m_newTargetPossition = false;
        }
        if (m_entranceSingleton && settings.nodeFancyEntrance) {
          setNewNodePossition(
            Constants.Animation.EASING_DESCENDING,
            currentDuration,
            settings.duration
          );
        } else if (!isHovered) {
          setNewNodePossition(
            settings.nodeEase,
            currentDuration,
            settings.duration
          );
        }

        if (draw && typeof draw === "function") {
          draw();
        }
      } else if (
        currentDuration >=
        settings.duration + settings.restNodeMovements
      ) {
        m_lastFrameUpdate = timestamp;
        m_newTargetPossition = true;
        m_entranceSingleton = false;
      }
    }
    m_requestId = m_requestAnimationFrame(step);
  };

  const setupClusterNodes = () => {
    let nodesTmp = [];

    for (let i = 0; i < settings.numberOfNodes; i++) {
      let currentNode = {
        x: 0,
        y: 0,
        z: 0,
        text: props.nodes[i].text,
        link: props.nodes[i].link,
      };

      if (settings.randomizePolygonMeshNetworkFormation) {
        currentNode.x = Math.random() * settings.canvasWidth;
        currentNode.y = Math.random() * settings.canvasHeight;
      } else {
        currentNode = settings.specifyPolygonMeshNetworkFormation(i);
      }

      let phi = Math.acos(Math.random() * 2 - 1);
      currentNode.z =
        settings.node3dDepthDistance +
        settings.node3dDepthDistance * Math.cos(phi);

      settings.nodeDotColor = Array.isArray(settings.nodeDotColor)
        ? settings.nodeDotColor
        : new Array(settings.nodeDotColor);
      settings.nodeLineColor = Array.isArray(settings.nodeLineColor)
        ? settings.nodeLineColor
        : new Array(settings.nodeLineColor);
      settings.nodeFillColor = Array.isArray(settings.nodeFillColor)
        ? settings.nodeFillColor
        : new Array(settings.nodeFillColor);
      settings.nodeFillGradientColor = Array.isArray(
        settings.nodeFillGradientColor
      )
        ? settings.nodeFillGradientColor
        : new Array(settings.nodeFillGradientColor);

      if (settings.nodeOverflow === false) {
        let maxDistance = settings.nodeMovementDistance + settings.nodeDotSize;
        let maxHeight = settings.canvasHeight - maxDistance;
        let maxWidth = settings.canvasWidth - maxDistance;

        m_nodeMovementDistance = Math.min(
          Math.min(settings.nodeMovementDistance, maxWidth),
          Math.min(settings.nodeMovementDistance, maxHeight)
        );

        currentNode.x = Math.floor(
          currentNode.x + maxDistance > settings.canvasWidth
            ? maxWidth
            : currentNode.x
        );
        currentNode.x = Math.floor(
          currentNode.x - maxDistance < maxDistance
            ? maxDistance
            : currentNode.x
        );
        currentNode.y = Math.floor(
          currentNode.y + maxDistance > settings.canvasHeight
            ? maxHeight
            : currentNode.y
        );
        currentNode.y = Math.floor(
          currentNode.y - maxDistance < maxDistance
            ? maxDistance
            : currentNode.y
        );
      }
      nodesTmp.push({
        currentX: currentNode.x,
        originX: currentNode.x,
        startX: currentNode.x,
        targetX: currentNode.x,
        currentY: currentNode.y,
        originY: currentNode.y,
        startY: currentNode.x,
        targetY: currentNode.y,
        originZ: currentNode.z,
        zAlpha: 1,
        text: currentNode.text,
        link: currentNode.link,
      });

      nodesTmp[i].UnconnectedNode = settings.numberOfUnconnectedNode > i;
    }

    for (let i = 0; i < nodesTmp.length; i++) {
      let node = nodesTmp[i];

      node.Closest = nodesTmp
        .filter((item) => item !== node)
        .sort((a, b) => {
          if (getDistance(node, a) > getDistance(node, b)) return 1;
          if (getDistance(node, a) < getDistance(node, b)) return -1;
          return 0;
        })
        .splice(0, settings.nodeRelations);

      node.nodeDotColor =
        settings.nodeDotColor[
          settings.nodeDotColoringSchema === Constants.Coloring.COLORING_RANDOM
            ? Math.floor(Math.random() * settings.nodeDotColor.length)
            : i % settings.nodeDotColor.length
        ];
      node.nodeLineColor =
        settings.nodeLineColor[
          settings.nodeLineColoringSchema === Constants.Coloring.COLORING_RANDOM
            ? Math.floor(Math.random() * settings.nodeLineColor.length)
            : i % settings.nodeLineColor.length
        ];
      node.nodeFillColor =
        settings.nodeFillColor[
          settings.nodeFillColoringSchema === Constants.Coloring.COLORING_RANDOM
            ? Math.floor(Math.random() * settings.nodeFillColor.length)
            : i % settings.nodeFillColor.length
        ];
      node.nodeFillGradientColor =
        settings.nodeFillGradientColor[
          settings.nodeFillGradientColoringSchema ===
          Constants.Coloring.COLORING_RANDOM
            ? Math.floor(Math.random() * settings.nodeFillGradientColor.length)
            : i % settings.nodeFillGradientColor.length
        ];

      setAlphaLevel(node);
    }
    setNodes(nodesTmp);
  };

  const getDistance = (firstNode, secondNode) => {
    return Math.sqrt(
      Math.pow(firstNode.currentX - secondNode.currentX, 2) +
        Math.pow(firstNode.currentY - secondNode.currentY, 2)
    );
  };

  const setAlphaLevel = (node) => {
    let screenDistance = Math.sqrt(
      Math.pow(settings.canvasWidth, 2) + Math.pow(settings.canvasHeight, 2)
    );
    let nodeDistance = 0;
    for (let i in node.Closest) {
      nodeDistance += getDistance(
        node.Closest[i],
        node.Closest[(i + 1) % node.Closest.length]
      );
    }

    let generalAlpha = 1 - nodeDistance / screenDistance;
    node.lineAlpha = generalAlpha * settings.nodeLineAlpha;

    if (generalAlpha > 0.85) {
      node.fillAlpha = generalAlpha * settings.nodeFillAlpha;
      node.lineAlpha = settings.nodeLineAlpha;
      node.dotAlpha = settings.nodeDotAlpha;
    } else if (generalAlpha < 0.8 && generalAlpha > 0.7) {
      node.fillAlpha = 0.5 * generalAlpha * settings.nodeFillAlpha;
      node.lineAlpha = settings.nodeLineAlpha;
      node.dotAlpha = settings.nodeDotAlpha;
    } else if (generalAlpha < 0.7 && generalAlpha > 0.4) {
      node.fillAlpha = 0.2 * generalAlpha * settings.nodeFillAlpha;
    } else {
      node.fillAlpha = 0;
    }

    node.dotAlpha = Math.max(generalAlpha * settings.nodeDotAlpha, 0.4);
  };

  const setNewTargetPossition = () => {
    let allNewTargetX = [];

    for (let i in nodes) {
      let newTargetX = calculateNewTargetPossition(nodes[i].originX);
      let newTargetY = calculateNewTargetPossition(nodes[i].originY);
      nodes[i].targetX = newTargetX;
      nodes[i].targetY = newTargetY;

      nodes[i].startX = nodes[i].currentX;
      nodes[i].startY = nodes[i].currentY;

      nodes[i].NodePrediction =
        settings.nodeDotPrediction > 0 &&
        Math.random() <= settings.nodeDotPrediction;

      allNewTargetX.push(nodes[i].targetX);
    }

    if (settings.node3dRotateAxis === Constants.Rotation.MEDIAN_AXIS) {
      allNewTargetX.sort((a, b) => a - b);

      var half = Math.floor(allNewTargetX.length / 2);

      if (allNewTargetX.length % 2) {
        m_rotationAxis = allNewTargetX[half];
      } else {
        m_rotationAxis = Math.floor(
          (allNewTargetX[half - 1] + allNewTargetX[half]) / 2.0
        );
      }
    } else if (settings.node3dRotateAxis === Constants.Rotation.LEFT_AXIS) {
      m_rotationAxis = 0;
    } else if (settings.node3dRotateAxis === Constants.Rotation.RIGHT_AXIS) {
      m_rotationAxis = settings.canvasWidth;
    } else {
      m_rotationAxis = settings.canvasWidth / 2;
    }

    m_3dRotateOnNthNodeMovement++;
  };

  const calculateNewTargetPossition = (originValue) => {
    return (
      originValue +
      (Math.random() < 0.5 ? -Math.random() : Math.random()) *
        m_nodeMovementDistance
    );
  };

  const setNewNodePossition = (easing, currentTime) => {
    m_turnSpeed = (2 * Math.PI) / (settings.duration * settings.animationFps);
    m_turnAngle = (m_turnAngle + m_turnSpeed) % (2 * Math.PI);
    m_sinAngle = Math.sin(
      getEasing(
        settings.node3dRotatEase,
        currentTime,
        m_turnSpeed,
        2 * Math.PI,
        settings.duration
      )
    );
    m_cosAngle = Math.cos(
      getEasing(
        settings.node3dRotatEase,
        currentTime,
        m_turnSpeed,
        2 * Math.PI,
        settings.duration
      )
    );

    for (let i in nodes) {
      nodes[i].currentX = getEasing(
        easing,
        currentTime,
        nodes[i].startX,
        nodes[i].targetX,
        settings.duration
      );
      nodes[i].currentY = getEasing(
        easing,
        currentTime,
        nodes[i].startY,
        nodes[i].targetY,
        settings.duration
      );

      if (
        settings.node3dRotate &&
        m_3dRotateOnNthNodeMovement % settings.node3dRotateOnNthNodeMovement ===
          0
      ) {
        let m_dist = m_rotationAxis - nodes[i].currentX;
        m_rotX =
          -m_cosAngle * m_dist +
          m_sinAngle * (nodes[i].originZ - settings.node3dDepthDistance);
        m_rotZ =
          -m_sinAngle * m_dist +
          m_cosAngle * (nodes[i].originZ - settings.node3dDepthDistance);
        nodes[i].currentX = m_rotX + m_rotationAxis;

        if (settings.nodeOverflow === false) {
          let maxHeight = settings.canvasHeight - settings.nodeDotSize;
          let maxWidth = settings.canvasWidth - settings.nodeDotSize;
          nodes[i].currentX = Math.floor(
            nodes[i].currentX > maxWidth ? maxWidth : nodes[i].currentX
          );
          nodes[i].currentX = Math.floor(
            nodes[i].currentX < settings.nodeDotSize
              ? settings.nodeDotSize
              : nodes[i].currentX
          );
          nodes[i].currentY = Math.floor(
            nodes[i].currentY > settings.canvasHeight
              ? maxHeight
              : nodes[i].currentY
          );
          nodes[i].currentY = Math.floor(
            nodes[i].currentY < settings.nodeDotSize
              ? settings.nodeDotSize
              : nodes[i].currentY
          );
        }
        nodes[i].zAlpha = 1 - m_rotZ / (m_rotationAxis / 2);
        let minAlpha = settings.node3dRotateDepthAlpha;
        nodes[i].zAlpha =
          nodes[i].zAlpha > 1
            ? 1
            : nodes[i].zAlpha < minAlpha
            ? minAlpha
            : nodes[i].zAlpha;

        m_3dRotateOnNthNodeMovement = 0;
      }
    }
  };

  const getEasing = (
    easing,
    currentTime,
    startPossition,
    targetPossition,
    endTime
  ) => {
    switch (easing) {
      case Constants.Animation.EASING_LINEAR:
        return (
          (targetPossition - startPossition) * (currentTime / endTime) +
          startPossition
        );
      case Constants.Animation.EASING_EASEIN:
        currentTime /= endTime;
        return (
          (targetPossition - startPossition) * Math.pow(currentTime, 2) +
          startPossition
        );
      case Constants.Animation.EASING_EASEOUT:
        currentTime /= endTime;
        return (
          -(targetPossition - startPossition) *
            currentTime *
            (currentTime - 2) +
          startPossition
        );
      case Constants.Animation.EASING_EASEINOUT:
        currentTime /= endTime / 2;
        if (currentTime < 1)
          return (
            ((targetPossition - startPossition) / 2) *
              Math.pow(currentTime, 2) +
            startPossition
          );
        return (
          (-(targetPossition - startPossition) / 2) *
            ((currentTime - 1) * (currentTime - 1 - 2) - 1) +
          startPossition
        );
      case Constants.Animation.EASING_ACCELERATE:
        currentTime /= endTime / 2;
        if (currentTime < 1)
          return (
            ((targetPossition - startPossition) / 2) *
              Math.pow(currentTime, 3) +
            startPossition
          );
        return (
          ((targetPossition - startPossition) / 2) *
            (Math.pow(currentTime - 2, 3) + 2) +
          startPossition
        );
      case Constants.Animation.EASING_DESCENDING:
        currentTime /= endTime / 2;
        if (currentTime < 1)
          return (
            (targetPossition - startPossition) / Math.pow(currentTime, 3) +
            startPossition
          );
        return (
          (targetPossition - startPossition) /
            (Math.pow(currentTime - 2, 3) + 2) +
          startPossition
        );
      default:
        return getEasing(
          Constants.Animation.EASING_LINEAR,
          currentTime,
          startPossition,
          targetPossition,
          endTime
        );
    }
  };

  const draw = () => {
    canvas.current
      ?.getContext("2d")
      .clearRect(0, 0, settings.canvasWidth, settings.canvasHeight);
    for (let i in nodes) {
      drawLines(nodes[i]);
      drawCircle(nodes[i]);
    }
  };

  const drawLines = (node) => {
    if (!node.lineAlpha > 0 && !node.fillAlpha > 0) return;

    for (let i in node.Closest) {
      let lineConnection =
        node.Closest[i].UnconnectedNode === false &&
        node.Closest[(i + 1) % node.Closest.length].UnconnectedNode === false;
      let drawCloseUnconnection =
        settings.ConnectUnconnectedNodes === true &&
        getDistance(node, node.Closest[i]) <=
          settings.ConnectUnconnectedNodesDistance;

      if (lineConnection || drawCloseUnconnection) {
        if (node.lineAlpha > 0 && settings.drawLineNode) {
          if (drawCloseUnconnection) {
            let connectioDist =
              (1 -
                getDistance(node, node.Closest[i]) /
                  settings.ConnectUnconnectedNodesDistance) *
              1.8;
            connectioDist = connectioDist > 1 ? 1 : connectioDist;
            drawLineNodeConnection(node, i, connectioDist);
          } else {
            drawLineNodeConnection(node, i, 1);
          }
        }

        if (settings.nodeFillSapce && node.fillAlpha > 0 && lineConnection) {
          drawFillNodeConnection(node, i);
        }
      }
    }
  };

  const drawLineNodeConnection = (node, i, connectioAlpha) => {
    if (!canvas.current) return;
    canvas.current.getContext("2d").beginPath();
    canvas.current.getContext("2d").moveTo(node.currentX, node.currentY);
    canvas.current
      .getContext("2d")
      .lineTo(node.Closest[i].currentX, node.Closest[i].currentY);
    canvas.current.getContext("2d").strokeStyle =
      "rgba(" +
      node.nodeLineColor +
      "," +
      node.lineAlpha * node.zAlpha * connectioAlpha +
      ")";
    canvas.current.getContext("2d").stroke();
  };

  const drawFillNodeConnection = (node, i) => {
    if (!canvas.current) return;
    canvas.current.getContext("2d").beginPath();
    canvas.current.getContext("2d").moveTo(node.currentX, node.currentY);
    canvas.current
      .getContext("2d")
      .lineTo(node.Closest[i].currentX, node.Closest[i].currentY);
    canvas.current
      .getContext("2d")
      .lineTo(
        node.Closest[(i + 1) % node.Closest.length].currentX,
        node.Closest[(i + 1) % node.Closest.length].currentY
      );

    if (
      node.nodeFillGradientColor !== null &&
      isFinite(node.currentX) &&
      isFinite(node.currentY) &&
      isFinite(node.Closest[i].currentX) &&
      isFinite(node.Closest[i].currentY)
    ) {
      var gradient = canvas.current
        .getContext("2d")
        .createLinearGradient(
          node.currentX,
          node.currentY,
          node.Closest[i].currentX,
          node.Closest[i].currentY
        );
      gradient.addColorStop(
        0,
        "rgba(" + node.nodeFillColor + "," + node.fillAlpha * node.zAlpha + ")"
      );
      gradient.addColorStop(
        1,
        "rgba(" +
          node.nodeFillGradientColor +
          ", " +
          node.fillAlpha * node.zAlpha +
          ")"
      );
      canvas.current.getContext("2d").fillStyle = gradient;
    } else {
      canvas.current.getContext("2d").fillStyle =
        "rgba(" + node.nodeFillColor + "," + node.fillAlpha * node.zAlpha + ")";
    }

    canvas.current.getContext("2d").fill();
  };

  const drawCircle = (node) => {
    if (!node.dotAlpha > 0) return;
    if (!canvas.current) return;

    canvas.current.getContext("2d").beginPath();

    canvas.current.getContext("2d").font = settings.nodeFont;
    canvas.current
      .getContext("2d")
      .fillText(node.text, node.currentX, node.currentY);
    const msr = canvas.current.getContext("2d").measureText(node.text);
    node.textWidth = msr.width;

    canvas.current.getContext("2d").fillStyle =
      "rgba(" + node.nodeDotColor + ", " + node.dotAlpha * node.zAlpha + ")";

    if (settings.nodeGlowing) {
      canvas.current.getContext("2d").shadowBlur = 10;
      canvas.current.getContext("2d").shadowColor =
        "rgba(" + node.nodeDotColor + ", " + node.dotAlpha * node.zAlpha + ")";
    }
    if (node.NodePrediction === true) {
      let nodeSize = settings.nodeDotSize * Math.PI;
      let nodeMiddleSize = nodeSize / 2;
      canvas.current.getContext("2d").font = nodeSize + "px Arial";
      canvas.current
        .getContext("2d")
        .strokeRect(
          node.targetX - nodeMiddleSize,
          node.targetY - nodeMiddleSize,
          nodeSize,
          nodeSize
        );
      canvas.current
        .getContext("2d")
        .fillText(
          node.targetX + ", " + node.targetY,
          node.targetX + nodeSize,
          node.targetY - nodeMiddleSize
        );
    }
    canvas.current.getContext("2d").fill();
  };

  const onMouseMove = (ev) => {
    if (!canvas.current) return;
    var rect = canvas.current.getBoundingClientRect();
    var x = ev.clientX - rect.left;
    var y = ev.clientY - rect.top;

    let node = nodes.some((e) => {
      return (
        x >= e.currentX &&
        x <= e.currentX + e.textWidth &&
        y >= e.currentY - 30 &&
        y <= e.currentY + 30
      );
    });

    if (node) {
      menu.current.style.cursor = "pointer";
      stopMoving();
    } else {
      menu.current.style.cursor = "";
      node = null;
      startMoving();
    }
  };

  const onClick = (ev) => {
    if (!canvas.current) return;
    var rect = canvas.current.getBoundingClientRect();
    var x = ev.clientX - rect.left;
    var y = ev.clientY - rect.top;

    let node = nodes.find((e) => {
      return (
        x >= e.currentX &&
        x <= e.currentX + e.textWidth &&
        y >= e.currentY - 30 &&
        y <= e.currentY + 30
      );
    });

    if (node) {
      if (year && _.range(2005, +moment().format("YYYY")).includes(+year)) {
        navigate("/photography/" + year + "/" + node.link);
      } else {
        navigate(node.link);
      }
      stop();
    }
  };

  const removeEventListener = () => {
    window.removeEventListener("resize", refresh);
  };

  const startPage = () => {
    refresh();

    window.addEventListener("resize", refresh, false);

    lair.current.scrollIntoView({
      behavior: "auto",
      block: "center",
      inline: "center",
    });
  };

  useEffect(() => {
    startPage();

    return () => {
      removeEventListener();
    };
  }, []);

  useEffect(() => {
    if (nodes.length > 0) start();
  }, [nodes]);

  useEffect(() => {
    startPage();
  }, [props.nodes]);

  return (
    <div className="screen-200 rel" ref={menu}>
      <div className="screen-200">
        <canvas
          ref={canvas}
          onMouseMove={onMouseMove}
          onClick={onClick}
        ></canvas>
      </div>
      <div className="lair" ref={lair}></div>
      {props.children}
    </div>
  );
}

export default Polygone;
