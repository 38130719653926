import { AlertColor } from "@mui/material/Alert";
import { Dispatch, SetStateAction, createContext } from "react";

export default createContext<
  [
    string,
    Dispatch<SetStateAction<string>>,
    AlertColor,
    Dispatch<SetStateAction<AlertColor>>,
    boolean,
    Dispatch<SetStateAction<boolean>>
  ]
>(["", () => undefined, "success", () => undefined, false, () => undefined]);
