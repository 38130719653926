import { debounce } from "lodash";
import { MenuItem, Select, TextField } from "@mui/material";
import { PlusOutlined } from "@ant-design/icons";
import { SEASON } from "../enums/season.enum";
import { Upload } from "antd";
import { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AlertContext from "context/alert.context";
import axios from "axios";
import Button from "@mui/material/Button";
import LoaderContext from "../context/loader.context";
import socket from "../socket/socket";
import type { RcFile } from "antd/es/upload";
import type { UploadFile } from "antd/es/upload/interface";

function UploadForm() {
  const navigate = useNavigate();

  const [year, setYear] = useState<number>();
  const [season, setSeason] = useState<number>(SEASON.FALL);

  const [, setLoading] = useContext(LoaderContext);
  const [, setMessage, , setSeverity, , setOpen] = useContext(AlertContext);
  const [files, setFiles] = useState<UploadFile[]>([]);

  useEffect(() => {
    socket.on("images-uploaded", (name) => {
      setMessage(`${name} uploaded`);
      setSeverity("success");
      setOpen(true);
    });
    socket.on("images-not-uploaded", () => {
      setLoading(false);
      setMessage("An image did not upload");
      setSeverity("error");
      setOpen(true);
    });
    socket.on("all-uploaded", () => {
      setFiles([]);
      setLoading(false);
      setMessage("Uploaded");
      setSeverity("success");
      setOpen(true);
      navigate("/images");
    });

    return () => {
      socket.off("images-uploaded");
      socket.off("images-not-uploaded");
      socket.off("all-uploaded");
    };
  }, []);

  const handleChange = useCallback(
    debounce(({ fileList }) => {
      if (fileList.length > 200) {
        setMessage("Max 200 images");
        setOpen(true);
        setSeverity("error");
        return;
      }
      setFiles(fileList);
    }, 50),
    []
  );

  const beforeUpload = (file: RcFile) => {
    if (!["image/jpg", "image/jpeg", "image/png"].includes(file.type)) {
      setMessage(file.name + " is not an image");
      setOpen(true);
      setSeverity("error");
      return Upload.LIST_IGNORE;
    }
    return false;
  };

  const onUpload = () => {
    if (year == undefined || isNaN(year)) {
      setMessage("Year in not a number");
      setSeverity("warning");
      setOpen(true);
      return;
    }
    const formData = new FormData();
    files.forEach((e: UploadFile) => {
      formData.append("files", e.originFileObj as RcFile, e.fileName);
    });
    formData.append("socketId", socket.id as string);
    formData.append("year", year.toString());
    formData.append("season", season.toString());
    setLoading(true);

    axios
      .post("/api/images", formData, {
        headers: {
          "content-type": "multipart/form-data",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then(() => undefined)
      .catch((e) => {
        setLoading(false);
        setMessage(e?.response?.data?.message);
        setSeverity("error");
        setOpen(true);
      });
  };

  return (
    <>
      <div className="upload-cont">
        <div className="option-cont">
          <TextField
            label="Year"
            variant="outlined"
            onChange={(e) => setYear(+e.target.value)}
            value={year || ""}
            type="number"
            className="year-cont"
          />
          <Select
            value={season}
            label="Season"
            onChange={(e) => setSeason(+e.target.value)}
          >
            {Object.keys(SEASON)
              .slice(0, 3)
              .map((x) => (
                <MenuItem key={x} value={x}>
                  {SEASON[x]}
                </MenuItem>
              ))}
          </Select>
        </div>
        <Upload
          accept=".jpg,.jpeg,.png"
          beforeUpload={beforeUpload}
          className="upload"
          fileList={files}
          listType="picture-card"
          multiple
          onChange={handleChange}
          showUploadList={{ showPreviewIcon: false }}
        >
          <PlusOutlined />
        </Upload>
        <Button
          variant="outlined"
          onClick={onUpload}
          className="upload-btn"
          disabled={files.length === 0}
        >
          Upload
        </Button>
      </div>
    </>
  );
}

export default UploadForm;
